import gql from "graphql-tag";

export const ReadJobs = gql`
    query readJobs {
        result: readJobs {
            name
            description
            when
        }
    }
`;

export const ReadStatisticsInvoices = gql`
    query readStatisticsInvoices {
        statistics: readStatisticsInvoices {
            lastUpdate
            overview {
                totalCount
                totalPaidCount
                totalAmount
            }
            histograms {
                cumulativeCount {
                    count
                    paidCount
                    time
                }
                paidAmount {
                    amount
                    count
                    time
                }
            }
        }
    }
`;

export const ReadStatisticsIssuers = gql`
    query readStatisticsIssuers {
        statistics: readStatisticsIssuers {
            lastUpdate
            overview {
                totalCount
                totalLogoCount
                totalSupportCount
                totalInvoiceRequests
                totalDebtRequests
            }
            histograms {
                registration {
                    count
                    time
                }
                cumulativeRegistration {
                    count
                    logoCount
                    supportCount
                    time
                }
                requests {
                    debtCount
                    invoiceCount
                    time
                }
                cumulativeRequests {
                    debtCount
                    invoiceCount
                    time
                }
            }
        }
    }
`;

export const ReadStatisticsUsers = gql`
    query readStatisticsUsers {
        statistics: readStatisticsUsers {
            lastUpdate
            overview {
                totalCount
                totalActiveCount
                totalInactiveCount
                totalDeletedCount
                ageAverage
            }
            histograms {
                registration {
                    count
                    ageAverage
                    activeCount
                    inactiveCount
                    deletedCount
                    time
                }
                cumulativeRegistration {
                    count
                    ageAverage
                    activeCount
                    inactiveCount
                    deletedCount
                    time
                }
            }
        }
    }
`;

export const ReadStatisticsChurn = gql`
    query readStatisticsChurn {
        churnStats: readStatisticsChurn {
            Year
            Month
            Count
        }
    }
`;

export const ReadStatisticsAccess = gql`
    query readStatisticsAccess {
        statistics: readStatisticsAccess {
            lastUpdate
            histograms {
                access {
                    count
                    countLogout
                    countBankIdSignature
                    countBankIdSession
                    countDeviceIos
                    countDeviceAndroid
                    time
                }
            }
        }
    }
`;

export const ReadStatisticsSubscriptions = gql`
    query readStatisticsSubscriptions {
        statistics: readStatisticsSubscriptions {
            lastUpdate
            overview {
                totalCount
                totalRefundedCount
                activeCount
                activeDistribution {
                    subscription {
                        id
                        type
                        period
                        amount
                    }
                    count
                }
            }
            histograms {
                overview {
                    count
                    refundedCount
                    activeCount
                    time
                }
            }
        }
    }
`;

export const ReadStatisticalReports = gql`
    query statisticalReports {
        reports: statisticalReports {
            fileName
            region
            contentType
            createdAt
        }
    }
`

export const ReadStatisticalReportContent = gql`
    query statisticalReportContent($fileName: String!) {
        reportContent: statisticalReportContent(fileName: $fileName) {
            signedUrl
        }
    }
`
