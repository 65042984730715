import Table from "react-bootstrap/Table";
import React from "react";
import {Button, Card} from "react-bootstrap";
import {useStatisticalReportContentLazyQuery, useStatisticalReportsQuery} from "../types/graphql";
import {Loading} from "./Loading";
import {Error} from "./Error";
import {getRegionNameByCode} from "../helper/region";
import moment from "moment";

export function StatisticsReportTable() {

  const {
    data,
    loading,
    error
  } = useStatisticalReportsQuery()

  const [getReportContent] = useStatisticalReportContentLazyQuery()

  return (
    <Card className='mb-4'>
      <Card.Header>
        <h5 className="dark">Reports</h5>
      </Card.Header>
      <Card.Body>
        <Loading isLoading={loading}/>
        <Error error={error?.graphQLErrors[0].message}/>

        {data && (
          <Table striped bordered hover>
            <thead>
            <tr>
              <th>Region</th>
              <th>Date</th>
              <th>Actions</th>
            </tr>
            </thead>
            <tbody>

            {data?.reports.map((report, k) => (
              <tr key={k}>
                <td>{getRegionNameByCode(report.region)}</td>
                <td>{moment(report.createdAt).format("DD/MM/YYYY")}</td>
                <td>
                  <Button
                    variant={'dark'}
                    size={'sm'}
                    onClick={async () => {
                      try {
                        const {data} = await getReportContent({variables: {fileName: report.fileName}})
                        // Create a temporary anchor element
                        const a = document.createElement("a");
                        a.href = data?.reportContent?.signedUrl || '';
                        a.download = report.fileName;
                        a.target = "_blank"
                        document.body.appendChild(a);
                        a.click();

                        // Clean up
                        document.body.removeChild(a);
                      } catch (e) {
                        console.error(e)
                      }
                    }}
                  >
                    Download
                  </Button>
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
        )}
      </Card.Body>
    </Card>
  )
}
