export function getRegionNameByCode(code: string) {
  switch (code) {
    case 'swe':
    case 'sv':
      return '🇸🇪 Sweden';
    case 'nor':
    case 'no':
      return '🇳🇴 Norway';
  }
}
